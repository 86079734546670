import React from 'react';
import { Modal } from 'react-bootstrap';

class RespostaAlerta extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            msg: ''
        }
        this.Show = (message) => this.setState({ show: true, msg: message });
    }
    render() {
        return (
            <Modal show={this.state.show}>
                <Modal.Header style={{ color: '#000' }}>
                    ALERTA!
                </Modal.Header>
                <Modal.Body style={{ color: '#000' }}>
                    {this.state.msg}
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={e => this.setState({ show: false })} type="button" className='btn btn-danger'>Fechar</button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default RespostaAlerta;