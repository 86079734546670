import React from 'react';

const Loader = ({ Show }) => {
    return (
        <div className="loader-container" style={{ display: Show ? 'flex' : 'none' }}>
            <div className="loader"></div>
        </div>
    );
};

export default Loader;