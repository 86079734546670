import { useState, useRef } from "react";
import './style.css'
import axios from "axios";
import Loader from "./Loader";
import RespostaAlerta from "./Alerta";

function App() {
  const [codPatologia, setCodPatologia] = useState('');
  const [ptgAtual, setPtgAtual] = useState(null);
  const url = 'https://hmrq2holisticus.azimute.med.br/api';
  // const url = 'https://localhost:44359/api';
  const token = '5ad42ca9-5cad-32c7-ab14-274fabec6203';
  const config = {
    headers: {
      'Authorization': `Bearer ${token}`
    }
  }
  const [quiz, setQuiz] = useState({ questions: [] });
  const [opcoesSelecionadas, setOpcoesSelecionadas] = useState([]);
  const [email, setEmail] = useState('');
  const [nome, setNome] = useState('');
  const [loading, setLoading] = useState(false);
  const Alerta = useRef();

  const setInitialState = () => {
    setQuiz({ questions: [] });
    setOpcoesSelecionadas([]);
    setEmail('');
    setNome('');
    setLoading(false);
  }

  const BuscarQuestionario = () => {
    setLoading(true);
    axios.get(`${url}/quiz/FullQuiz/${codPatologia}`, config)
      .then(response => {
        let copy = response.data;

        let ptgcod = response.data.questions.filter(x => x.sequence === 1)[0]
        setPtgAtual(ptgcod.code);
        copy.questions = copy.questions.sort((a, b) => a.sequence - b.sequence)
        setQuiz(copy)
        console.log(response.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        alert('Erro: ' + error.response.data)
        console.error('Error:', error);
      });
  }

  const OnchangeOpcoes = (value, index) => {
    if (value === "") return;

    const i = { [index]: value }
    let l = opcoesSelecionadas;
    if (l.filter(x => x[index]).length > 0) {
      l = l.filter(item => item !== l.filter(x => x[index])[0]);
    }

    l.push(i);
    setOpcoesSelecionadas(l);

    const obj = JSON.parse(value);
    setPtgAtual(obj.nextQuestionCode);
  }

  const handdleSubmit = () => {
    const paciente = {};
    paciente.fullName = nome;
    paciente.email = email;
    paciente.accepts = [{ "code": 16 }, { "code": 60 }];
    paciente.quizzes = [];
    paciente.pathology = { "code": codPatologia }
    opcoesSelecionadas.forEach(item => {
      for (let key in item) {
        if (item.hasOwnProperty(key)) {
          paciente.quizzes.push(JSON.parse(item[key]));
        }
      }
    })
    setLoading(true);
    axios.post(`${url}/Patient`, paciente, config).then(response => {
      if (response.data.result && response.data.result.message && response.data.result.message !== '') {
        Alerta.current.Show(response.data.result.message);
      } else {
        Alerta.current.Show("Processo realizado com sucesso!")
      }
      if (response.data.result && response.data.result.redirectLink && response.data.result.redirectLink !== '') {
        window.open(response.data.result.redirectLink);
      }
      setInitialState();
      setLoading(false);
    })
      .catch(error => {
        setLoading(false);
        Alerta.current.Show(error.response.data ? error.response.data : "Houve um erro na solicitação!")
        console.error('Error:', error);
      });
  }

  return (
    <>
      <Loader Show={loading} />
      <RespostaAlerta ref={Alerta} />
      <div className="container">
        <form className="row" onSubmit={e => { e.preventDefault(); BuscarQuestionario() }}>
          <div className="col-md-4" />
          <div className="col-md-4">
            <label>Cod. Patologia</label>
            <input disabled={quiz && quiz.questions.length > 0} className="form-control" required type="text" value={codPatologia} onChange={e => setCodPatologia(e.target.value)} />
          </div>
          <div className="col-md-4" style={{ marginTop: 22 }}>
            <button className="btn btn-success">Buscar</button>
          </div>
        </form>
        <hr />
        {quiz.description && <h3 style={{ textAlign: 'center' }}>{("" + quiz.description).split(" -")[0]}</h3>}
        <br />
        <div className="container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
          {quiz
            && quiz.questions.length > 0
            && ptgAtual == null
            && <div className="row" style={{ width: '500px' }}>
              <div className="col-md-12">
                <label>Nome e Sobrenome</label>
                <input className="form-control" required type="text" value={nome} onChange={e => setNome(e.target.value)} />
              </div>
              <div className="col-md-12">
                <label>Email</label>
                <input className="form-control" required type="text" value={email} onChange={e => setEmail(e.target.value)} />
              </div>
              <div className="row" style={{ marginTop: 20 }}>
                <div className="col-md-4" />
                <div className="col-md-4">
                  <button style={{ width: '100%' }} onClick={handdleSubmit} className="btn btn-primary">Enviar</button>
                </div>
                <div className="col-md-4" />

              </div>
            </div>}
          <br />
          <div className="row" style={{ width: '500px' }}>
            {quiz && quiz.questions.length > 0 && quiz.questions.map((pergunta, index) => {
              return (
                pergunta.code === ptgAtual && <div className="col-md-12">
                  <span>{pergunta.description}</span><br />
                  <small style={{ color: 'red' }}>{pergunta.auxiliaryDescription}</small>
                  <select onch className="form-control" onChange={({ target }) => OnchangeOpcoes(target.value, index)}>
                    <option value={''}>--SELECIONE--</option>
                    {pergunta.answers.map(opcao => (
                      <option value={JSON.stringify(opcao)}>{opcao.optionDescription}</option>
                    ))}
                  </select>
                  <br />
                </div>
              )
            })}
          </div>
        </div>

      </div >
    </>
  );
}

export default App;
